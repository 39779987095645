import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const { fetch } = window;

const bugsnagKeyP = fetch("/TDMS.UI_Core/api/Configuration/GetBugsnagKey").then(x => x.text());

const client = Bugsnag.start({
	apiKey: "d49eeea5ad167960edf85eb66fa06852", // my free throwaway bugsnag app
	plugins: [new BugsnagPluginReact()],
	onError: event =>
		bugsnagKeyP.then(key => {
			event.apiKey = key;
			return true;
		})
});

export default client.getPlugin("react")!.createErrorBoundary(React);
