import axios from "axios";
import { UserInfo } from "../../Shared/Models/userModel";

export class UserService {
	public static GetUserInfo(): Promise<UserInfo> {
		return axios.get<UserInfo>("portal/api/User").then(response => {
			return response.data;
		});
	}
}
