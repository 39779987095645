import React, { useEffect, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import { ModuleDto, NavigationDto } from "../Models/portalDtos";
import { tdmsModules } from "../Models/tdmsModulesEnum";
import { getModules } from "../Services/moduleService";
import { ModuleList } from "./ModuleList";
import "./ModuleNavigationDrawer.less";

interface ModuleNavigationDrawerProps {
	toggleVisible: () => void;
	module: tdmsModules;
	activeModule?: string;
}

export const ModuleNavigationDrawer = (props: ModuleNavigationDrawerProps) => {
	const [myModules, setMyModules] = useState<ModuleDto[] | null>(null);
	const [allModules, setAllModules] = useState<ModuleDto[] | null>(null);
	const activeModule = props.activeModule || tdmsModules[props.module];

	useEffect(() => {
		getModules().then((dto: NavigationDto) => {
			setMyModules(dto.myProducts);
			setAllModules(dto.allProducts);
		});
	}, []);

	return (
		<div className="module-navigation-drawer-container">
			<button className="ms2-drawer-toggle" onClick={props.toggleVisible}></button>
			<div className="modules-container">
				{myModules && (
					<ModuleList
						title="My Products"
						whichList="myProducts"
						activeModule={activeModule}
						modules={myModules}
					/>
				)}

				{allModules && (
					<ModuleList title="All Products" whichList="allProducts" modules={allModules} />
				)}
			</div>
			<Segment basic clearing>
				<Button className="tertiary" floated="right" onClick={props.toggleVisible}>
					Close
				</Button>
			</Segment>
		</div>
	);
};
