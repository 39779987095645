import React, { useState, useEffect } from "react";
import { Layout as SharedLayout } from "../Shared/Components/Layout";
import { UserInfo } from "../Shared/Models/userModel";
import { Routes } from "./Routes";
import { UserService } from "./Services/UserService";

export const Layout = () => {
	const [userInfo, setUserInfo] = useState<UserInfo>();

	useEffect(() => {
		UserService.GetUserInfo().then(result => setUserInfo(result));
	}, []);

	return (
		<SharedLayout
			headerDisplayName={"MS2 Portal"}
			userInfo={userInfo}
			helpLink={
				"/TDMS.UI_Core/Documentation/PassThrough?doc360RedirectPath=/docs/ms2-portal-quick-help"
			}>
			<Routes />
		</SharedLayout>
	);
};
