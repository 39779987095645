import axios from "axios";
import { NavigationDto } from "../Models/portalDtos";
const uri = "/api/navigation";

export const getModules = (): Promise<NavigationDto> => {
	return axios
		.get<NavigationDto>(`${uri}/getModules`, {
			headers: {
				"cache-control": "max-age=3600" // Cache for 1 hour
			}
		})
		.then(response => {
			return response.data;
		});
};
