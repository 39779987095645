// Do not update without also updating C:\source\TDMS\TDMS.Library\Enums\AccessLevel.cs
export enum AccessLevel {
	POWER_ADMIN = 1,
	SITE_MANAGER = 10,
	ADMIN = 20,
	USER = 30,
	PUBLIC = 50
}

export interface UserInfo {
	agencyId: number;
	username: string;
	agencyLoc: string;
	userId: number;
	accessLevel: AccessLevel;
	email: string;
}
