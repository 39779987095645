import React from "react";
import { Route, Switch } from "react-router-dom";
import { PortalMain } from "./Components/PortalMain";

export const Routes = () => (
	<Switch>
		<Route exact path="/portal">
			<PortalMain />
		</Route>
	</Switch>
);
