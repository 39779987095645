//We have links to our own site, and we want to keep the referrer data for marketing purposes.
/* eslint "react/jsx-no-target-blank": ["warn", { "allowReferrer": true }] */

import React from "react";
import "./Ms2Header.less";
import { ClientLogoBadge } from "./ClientLogoBadge";
import { UserMenu } from "../../Shared/Components/UserMenu";
import { Menu, Divider, Dropdown } from "semantic-ui-react";
import { UserInfo } from "../Models/userModel";
import auth from "../../Shared/Authorization";
import {
	redirectToOneLogin,
	newTabOneLogin,
	newTabOneLoginController
} from "../../Shared/Services/oneLoginService";

interface Ms2HeaderProps {
	moduleName: string | null;
	children?: React.ReactNode;
	displayDrawerToggle?: boolean;
	onDrawerToggleClick?: () => any;
	userInfo?: UserInfo | null;
	helpLink?: string;
	//if true we dont display login options in the user menu
	alwaysPublic?: boolean;
	//If not false, and userMenuItems are provided, we'll append links like privacy policy and terms of service
	appendStandardLinksToUserMenu?: boolean;
}

export const Ms2Header = (props: Ms2HeaderProps) => {
	const {
		moduleName,
		children,
		displayDrawerToggle,
		onDrawerToggleClick,
		userInfo,
		helpLink,
		appendStandardLinksToUserMenu
	} = props;
	return (
		<header className={"ms2-header" + (onDrawerToggleClick ? " with-drawer-toggle" : "")}>
			{displayDrawerToggle && onDrawerToggleClick && (
				<button
					type="button"
					className="ms2-drawer-toggle"
					onClick={onDrawerToggleClick}></button>
			)}
			<ClientLogoBadge />
			{children && <div className="ms2-header-content">{children}</div>}
			{moduleName && <div className="ms2-module-name">{moduleName}</div>}
			<div className="MS2-logo center-both">
				<a href="https://www.ms2soft.com" target="_blank" rel="noopener">
					<img src="/CDN/img/logos/MS2-Logo-White.svg" alt="MS2" title="MS2" />{" "}
				</a>
			</div>
			{(userInfo || helpLink) && (
				<UserMenu>
					<RenderUserMenu userInfo={userInfo} helpLink={helpLink} />
					{(typeof appendStandardLinksToUserMenu === "undefined" ||
						appendStandardLinksToUserMenu) && <RenderStandardMenuLinks />}
				</UserMenu>
			)}
		</header>
	);
};

const RenderUserMenu = (props: {
	userInfo?: UserInfo | null;
	helpLink?: string;
	alwaysPublic?: boolean;
}) => {
	const { userInfo, helpLink, alwaysPublic } = props;
	return (
		<>
			{userInfo?.username && (
				<Menu.Item fitted className="static logged-in-as">
					Logged in as: <br />
					{userInfo?.username}
				</Menu.Item>
			)}
			{helpLink && (
				<Dropdown.Item text="Help" as="a" href={helpLink} target="_blank" rel="noopener" />
			)}
			{userInfo && auth.IsSiteManagerOrAbove(userInfo.accessLevel) ? (
				<>
					<Dropdown.Item
						text="Settings"
						as="a"
						onClick={() => {
							newTabOneLogin("Status");
						}}
					/>
					<Dropdown.Item
						text="Users"
						as="a"
						onClick={() => {
							newTabOneLoginController("Admin", "Index");
						}}
					/>
					<Dropdown.Item
						text="Login As"
						as="a"
						onClick={() => {
							redirectToOneLogin("Impersonate");
						}}
					/>
				</>
			) : (
				<></>
			)}

			{userInfo && userInfo.username !== null ? (
				<>
					<Dropdown.Item
						text="Support"
						as="a"
						href="/TDMS.UI/Zendesk.ashx?command=tozendesk"
						target="_blank"
						rel="noopener"
					/>
					<Dropdown.Item
						text="Logout"
						as="a"
						onClick={() => {
							redirectToOneLogin("Logout");
						}}
					/>
				</>
			) : (
				!!alwaysPublic && (
					<Dropdown.Item
						text="Login"
						as="a"
						onClick={() => {
							redirectToOneLogin("");
						}}
					/>
				)
			)}
		</>
	);
};

const RenderStandardMenuLinks = props => {
	return (
		<React.Fragment>
			<Divider />
			<Menu.Item as="a" href="https://www.ms2soft.com/privacy" target="_blank" rel="noopener">
				Privacy Policy
			</Menu.Item>
			<Menu.Item as="a" href="https://www.ms2soft.com/terms" target="_blank" rel="noopener">
				Terms of Service
			</Menu.Item>
		</React.Fragment>
	);
};
