import React from "react";
import { ModuleDto } from "../Models/portalDtos";
import "./ModuleIcon.less";

interface ModuleIconProps {
	module: ModuleDto;
	parent: "myProducts" | "allProducts";
	activeModule?: string;
	moduleIconClickCallback?: () => any;
}

export const ModuleIcon = (props: ModuleIconProps) => {
	const { imageUri, homePageUri, marketingUri, displayPropertiesDto } = props.module;
	const enabled = props.parent === "myProducts" ? !!homePageUri?.length : true;

	const activeModule = props.activeModule && props.activeModule === displayPropertiesDto.acronym;

	const handleClick = () => {
		if (props.moduleIconClickCallback) {
			props.moduleIconClickCallback();
		}
		if (!enabled || (!homePageUri && !marketingUri)) {
			return;
		}

		window.open(homePageUri?.length ? homePageUri : marketingUri);
	};

	return (
		<div
			className={`module-icon-container ${enabled ? "" : "disabled"} ${activeModule ? "active-ms2-module" : ""}`}
			onClick={handleClick}>
			<div className="module-icon-image-container">
				{imageUri && (
					<img
						className="module-icon-image"
						src={imageUri}
						alt={displayPropertiesDto.acronym}
					/>
				)}
			</div>
			<div className="module-icon-label-container">
				{displayPropertiesDto.longName && (
					<span className="module-icon-long-name">{displayPropertiesDto.longName}</span>
				)}
				{displayPropertiesDto.acronym && (
					<span className="module-icon-acronym">{displayPropertiesDto.acronym}</span>
				)}
			</div>
		</div>
	);
};
