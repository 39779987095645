import React from "react";
import { Dropdown } from "semantic-ui-react";

export const UserMenu = (props: any) => {
	return (
		<div className="user-menu">
			<Dropdown floating icon={{ name: "user circle", size: "big" }}>
				<Dropdown.Menu className="open-right">{props.children}</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};
