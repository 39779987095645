import { AccessLevel } from "./Models/userModel";

//todo-msm use fp-ts if we need a lot of this kind of thing?
function equals<T>(val1: T) {
	return (val2: T) => val1 === val2;
}

function either<T>(check1: (x: T) => boolean) {
	return (check2: (x: T) => boolean) => (x: T) => check1(x) || check2(x);
}

const isPowerAdmin = equals(AccessLevel.POWER_ADMIN);

const isSiteManagerOrAbove = either(equals(AccessLevel.SITE_MANAGER))(isPowerAdmin);

const isAdminOrAbove = either(equals(AccessLevel.ADMIN))(isSiteManagerOrAbove);

const isUserOrAbove = either(equals(AccessLevel.USER))(isAdminOrAbove);

const authorization = {
	IsPowerAdmin: isPowerAdmin,
	IsSiteManagerOrAbove: isSiteManagerOrAbove,
	IsAdminOrAbove: isAdminOrAbove,
	IsUserOrAbove: isUserOrAbove
};

export default authorization;
