import React from "react";
import { Loader } from "semantic-ui-react";
import "./LoadingOverlay.less";

interface LoadingOverlayProps {
	isLoading: boolean;
	backgroundColor?: string;
	transparency?: number;
}

export const LoadingOverlay = (props: LoadingOverlayProps) => {
	const loadingStyles = {
		backgroundColor: props.backgroundColor || "inherit",
		display: props.isLoading ? "flex" : "none",
		opacity: props.transparency || 1
	};
	return (
		<div className="overlay-container" style={loadingStyles}>
			<Loader active inline size="massive" />
		</div>
	);
};
