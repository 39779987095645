export const redirectToOneLogin = (action: string) => {
	redirectToOneLoginController("Home", action);
};

const toOneLoginController = (controller: string, action: string, open: (url: string) => void) => {
	const url = `../TDMS.UI/OneLogin/${controller}/${action}?target=${encodeURIComponent(window.location.pathname)}`;
	open(url);
};

export const redirectToOneLoginController = (controller: string, action: string) =>
	toOneLoginController(controller, action, url => (window.location.href = url));

export const newTabOneLogin = (action: string) => newTabOneLoginController("Home", action);

export const newTabOneLoginController = (controller: string, action: string) =>
	toOneLoginController(controller, action, url => window.open(url + "&noback=true", "_blank"));
