import axios from "axios";

export const getAgencyLink = () => {
	return axios.get("/api/agency/getagencylink").then(response => response.data);
};

export const getAgencyDisclaimer = (agencyId?: number) => {
	const params = { agencyId: agencyId };

	return axios
		.get("/api/agency/getagencydisclaimer", { params: params })
		.then(response => response.data);
};

export const GetState = async (): Promise<string> => {
	return await axios.get("api/agency/GetAgencyState").then(resp => resp.data);
}
