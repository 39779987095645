import "semantic-ui-less/semantic.less"; //This includes global styles from semantic-ui\site\globals
import "@fortawesome/fontawesome-free/less/fontawesome.less"; //Semantic-UI has ported some, but not all font-awesome icons
import "@fortawesome/fontawesome-free/less/solid.less";
import "@fortawesome/fontawesome-free/less/regular.less";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AxiosConfig } from "../AxiosConfig";
import { App } from "./App";
import ErrorBoundary from "../ErrorBoundary";

const baseUrl: any = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const onServerException = (message: string) => {
	window.alert(message); //TODO: wire this up to display in HTML?
};

AxiosConfig.GlobalInit(baseUrl, onServerException);

const render = appRender => {
	return ReactDOM.render(
		<ErrorBoundary>
			<BrowserRouter basename={baseUrl}>{appRender()}</BrowserRouter>
		</ErrorBoundary>,
		rootElement
	);
};

render(App);

if ((module as any).hot) {
	(module as any).hot.accept("./App", () => {
		const NextApp = require("./App").default;
		render(NextApp);
	});
}
