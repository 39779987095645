import React, { useEffect, useState } from "react";
import { getAgencyLink } from "../Services/agencyService";
import "./ClientLogoBadge.less";

export const ClientLogoBadge = (props: any) => {
	const [agencyLoc, setAgencyLoc] = useState<string>();
	const [agencyLink, setAgencyLink] = useState<string>();

	useEffect(() => {
		const agencyLoc = window.location.host.split(".")[0];
		setAgencyLoc(agencyLoc);
		getAgencyLink().then(result => setAgencyLink(result));
	}, []);

	const clickAgencyLink = () => {
		if (agencyLink) {
			window.open(`https://${agencyLink}`, "_blank");
		}
	};

	return (
		<div className="client-logo-container" onClick={clickAgencyLink}>
			{agencyLoc && (
				<img
					className="client-logo"
					src={`/CDN/img/logos/logo-${agencyLoc}.png`}
					alt={agencyLoc}
				/>
			)}
		</div>
	);
};
