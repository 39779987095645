import React, { useState, useCallback, useMemo, ReactNode } from "react";
import { Sidebar } from "semantic-ui-react";
import { Ms2Header } from "./Ms2Header";
import { Ms2Footer } from "./Ms2Footer";
import { ModuleNavigationDrawer } from "./ModuleNavigationDrawer";
import { tdmsModules } from "../Models/tdmsModulesEnum";
import "./Layout.less";
import { UserInfo } from "../Models/userModel";

export const Layout = (props: {
	module?: tdmsModules;
	headerDisplayName: string;
	userInfo?: UserInfo | null;
	alwaysPublic?: boolean;
	helpLink?: string;
	headerContent?: React.ReactNode;
	children: ReactNode;
}) => {
	const [showPortalDrawer, setShowPortalDrawer] = useState<boolean>(false);
	const { module, headerDisplayName, headerContent, userInfo, alwaysPublic, helpLink, children } =
		props;
	const togglePortalDrawer = useCallback(() => {
		setShowPortalDrawer(!showPortalDrawer);
	}, [showPortalDrawer, setShowPortalDrawer]);

	//We coincidentally need the same styles on both the pushable and the pusher
	const style = useMemo(
		() => ({
			display: "flex",
			flexDirection: "column",
			flexGrow: 1
		}),
		[]
	);

	return (
		<div className="ms2-layout">
			<Sidebar.Pushable as="div" style={style}>
				<Sidebar
					animation="overlay"
					icon="labeled"
					visible={showPortalDrawer}
					width="very wide">
					{module && (
						<ModuleNavigationDrawer
							toggleVisible={togglePortalDrawer}
							module={module}
						/>
					)}
				</Sidebar>
				<Sidebar.Pusher style={style}>
					<Ms2Header
						moduleName={headerDisplayName}
						userInfo={userInfo}
						helpLink={helpLink}
						alwaysPublic={alwaysPublic}
						displayDrawerToggle={module !== undefined}
						onDrawerToggleClick={togglePortalDrawer}
						appendStandardLinksToUserMenu={false}>
						{headerContent}
					</Ms2Header>
					<div style={{ flex: 1, display: "flex" }}>
						<div style={{ flex: 1 }}>{children}</div>
					</div>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
			<Ms2Footer />
		</div>
	);
};
