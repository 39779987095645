import React from "react";
import { ModuleDto } from "../Models/portalDtos";
import { ModuleIcon } from "./ModuleIcon";
import "./ModuleList.less";

interface ModuleListProps {
	title: string;
	whichList: "myProducts" | "allProducts";
	activeModule?: string;
	modules: ModuleDto[];
}

export const ModuleList = (props: ModuleListProps) => {
	return (
		<div className="module-list-container">
			<span className="module-list-title">{props.title}</span>
			<div className="modules-container">
				{props.modules.map((module, index) => (
					<ModuleIcon
						key={index}
						module={module}
						activeModule={props.activeModule}
						parent={props.whichList}
					/>
				))}
			</div>
		</div>
	);
};
