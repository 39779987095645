export enum tdmsModules {
	TCDS = 1,
	NMDS = 2,
	TTDS = 4,
	OneLogin = 5,
	TaskScheduler = 6,
	TMC = 8,
	WOTS = 10,
	RSMS = 13,
	HPMS = 14,
	PMMS = 15,
	TCLS = 18,
	TSMS = 20,
	FIELDTOOL = 22,
	ESTS = 23,
	PLCS = 25,
	TNPS = 26,
	TFMS = 27,
	ERD = 28,
	AUTOPOLLING = 29,
	RoadsAndHighways = 30,
	BackupApi = 31,
	PublicationDatabase = 32,
	TV = 33,
	MBI = 34
}
