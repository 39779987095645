import React from "react";
import "./Ms2Footer.less";

//We have links to our own site, and we want to keep the referrer data for marketing purposes.
/* eslint "react/jsx-no-target-blank": ["warn", { "allowReferrer": true }] */

export const Ms2Footer = (props: any) => {
	return (
		<footer>
			<div>
				<a href="https://www.ms2soft.com/privacy" target="_blank" rel="noopener">
					Privacy Policy
				</a>
				<a href="https://www.ms2soft.com/terms" target="_blank" rel="noopener">
					Terms of Service
				</a>
				<span>&copy; {new Date().getFullYear().toString()} Midwestern Software Solutions, LLC (MS2)</span>
			</div>
		</footer>
	);
};
