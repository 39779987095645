import React, { useEffect, useState } from "react";
import { LoadingOverlay } from "../../Shared/Components/LoadingOverlay";
import { getModules } from "../../Shared/Services/moduleService";
import { NavigationDto } from "../../Shared/Models/portalDtos";
import { ModuleList } from "../../Shared/Components/ModuleList";
import { UserService } from "../Services/UserService";
import "./PortalMain.less";
import { UserInfo } from "../../Shared/Models/userModel";

export const PortalMain = (props: any) => {
	const [navigationDto, setNavigationDto] = useState<NavigationDto | null>(null);
	const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

	useEffect(() => {
		UserService.GetUserInfo().then(userInfo => setUserInfo(userInfo));
	}, []);

	useEffect(() => {
		getModules().then((navigationDto: NavigationDto) => {
			setNavigationDto(navigationDto);
		});
	}, []);

	const loading = userInfo === null || navigationDto === null;

	return (
		<main className="portal-main-container">
			<LoadingOverlay isLoading={loading} />
			{!loading && userInfo?.username === null && (
				<a
					href="../TDMS.UI/OneLogin/home/?target=%2fTDMS.UI_Core%2fportal"
					className="ui button portal-login-button">
					Login
				</a>
			)}
			{!loading && Array.isArray(navigationDto!.myProducts) && (
				<ModuleList
					title="My Products"
					whichList="myProducts"
					modules={navigationDto!.myProducts}
				/>
			)}
			{!loading && Array.isArray(navigationDto!.allProducts) && (
				<ModuleList
					title="All Products"
					whichList="allProducts"
					modules={navigationDto!.allProducts}
				/>
			)}
		</main>
	);
};
